import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import Breadcrumbs from "../../../components/breadcrumbs"
import Hero from "../../../components/hero"
import PageHeader from "../../../components/page-header"
import SectionHeader from "../../../components/section-header"
import Footer from "../../../components/footer"
import QROverlay from "../../../components/qr-overlay"
import ImageTeaser from "../../../components/image-teaser"

const imgKV = require("../../../images/skapa-demokratiska-samtal/dp-kollage-teaser.jpg")

import "../../../scss/site.scss"
//import StickyCta from "../../../components/sticky-cta"

const CreateDemocraticConversations = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [heading, setHeading] = useState(null)
  const [text, setText] = useState(null)
  const [linkText, setLinkText] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  const handleShowOverlay = (heading, text, linkText) => {
    if (heading) setHeading(heading)
    if (text) setText(text)
    if (linkText) setLinkText(linkText)
    setShowOverlay(!showOverlay)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Skapa Demokratiska Samtal | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/demokratiska-samtal/skapa-demokratiska-samtal"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/demokratiska-samtal/skapa-demokratiska-samtal"
        ></meta>
        <meta property="og:title" content="Skapa Demokratiska Samtal"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader></PageHeader>
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--demokratiska-samtal"
        >
          <Breadcrumbs
            modifier="indent"
            crumbs={props.pageContext.breadcrumb}
          />
          <Hero
            buttonLink="handledning"
            buttonText="Till läraren"
            modifier="has-breadcrumbs"
            title="Skapa demokratiska samtal"
          >
            <p>
              I ett demokratiskt samtal ska olika uppfattningar kunna
              diskuteras. De som deltar i samtalet förklarar sin åsikt men
              försöker också förstå vad de andra menar. Målet är att komma fram
              till något som går att enas om. Vad behövs för att ett samtal ska
              bli bra?
            </p>
            <p>
              Låt eleverna komma på vilka byggstenar som behövs för ett bra
              demokratiskt samtal och skapa kollage. Du kan dela övningen med
              dina elever.
            </p>
            <p>
              Vi rekommenderar att ni jobbar med övningen{" "}
              <em>Vad är rättvisa?</em> innan ni jobbar med denna övning.
            </p>
          </Hero>
          <section
            className="c-content-section"
            aria-labelledby="skapa-kollage"
          >
            <SectionHeader id="skapa-kollage" text="Övning: Skapa kollage" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <ImageTeaser
                      extraButtonText="Till Kollagen"
                      extraButtonTo="kollage-list"
                      qr="yes"
                      modifier="big"
                      img={imgKV}
                      imgAlt=""
                      title="Övning: Skapa kollage"
                      to="kollage"
                      linkButtonText="Till kollageverktyget"
                      buttonText="Länk och QR-kod"
                      onClick={() => {
                        handleShowOverlay(
                          "Skapa demokratiska samtal",
                          "Öppna med QR-kod eller skriv in länk:",
                          "bit.ly/kollageövning"
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {showOverlay && (
          <QROverlay
            show={showOverlay}
            heading={heading}
            text={text}
            linkText={linkText}
            toggleShow={handleShowOverlay}
          />
        )}
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default CreateDemocraticConversations
